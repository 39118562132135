import React from 'react'
import PropTypes from 'prop-types'
import { HTMLContent } from '../../components/common/Content'
import Layout from '../../components/Layout'
import { PostContainer } from '../../components/newsletter/PostContainer'
import { graphql } from 'gatsby'

// eslint-disable-next-line
export const NewsletterPageTemplate = ({ content, link, title }) => {
  return (
    <PostContainer>
      <div key={link}>
        <h2>{title}</h2>
        <HTMLContent content={content}></HTMLContent>
      </div>
    </PostContainer>
  )
}

NewsletterPageTemplate.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
}

const NewsletterPage = (props) => {
  const { data } = props
  const { feedNewsletter } = data
  return (
    <Layout>
      <NewsletterPageTemplate
        {...feedNewsletter}
        content={feedNewsletter.content.encoded}
      />
    </Layout>
  )
}

//allFeedNewsletter.nodes.map(({node}) => ({content: node.content.encoded, link: node.link, title: node.title }))} />

NewsletterPage.propTypes = {}

export default NewsletterPage

export const query = graphql`
  query ($id: String) {
    feedNewsletter(id: { eq: $id }) {
      title
      link
      content {
        encoded
      }
    }
  }
`
